<template>
  <div
    class="accordion container mt-70">
    <div class="border-b mt-30">
      <div class="accordion-container" v-b-toggle.accordion-1>
        <div class="flex align-center justify-between">
          <div class="title font-averta">
            como gerar mais prospects
          </div>
          <div class="icon _open">
            <i class="ri-add-line"></i>
          </div>
          <div class="icon _close">
            <i class="ri-close-fill"></i>
          </div>
        </div>
        <div class="summary">
          <p>
            Aprenda como gerar mais prospects e coloque mais combustível no seu processo de vendas.
          </p>
        </div>
      </div>
      <b-collapse
        id="accordion-1"
        class="collapse-item"
        accordion="my-accordion"
        role="tabpanel">
        <div class="accordion-container-item mb-20">
          <div class="title">
            Defina seu perfil de cliente ideal
          </div>
          <p class="description">
            O ICP (Ideal Customer Profile, ou Perfil de Cliente Ideal) é uma técnica que busca definir qual é o perfil de cliente com maior probabilidade de fechar um negócio com você, tendo como base nos seus clientes atuais.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/ideal-customer-profile-o-que-e-e-como-fazer-o-meu/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Enriqueça/valide os dados da sua lista
          </div>
          <p class="description">
            Na prospecção de clientes, o enriquecimento de dados é fundamental para construir listas de contatos que sejam eficientes, reduzindo sua taxa de spam e falando com quem você realmente quer.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/enriquecimento-de-dados/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Pegue o telefone e ligue!
          </div>
          <p class="description">
            Existe um receio por parte dos pré-vendedores de ligarem. No entanto, um trabalho fundamental para o sucesso da prospecção é ligar. Afinal, quando você tem um ICP bem definido e compreende a entrega de valor da sua solução, você não está ligando para vender algo que seu prospect não precisa, e sim uma solução que vai auxiliar no sucesso dele, portanto, não tenha medo de ligar.
          </p>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Separe sua operação comercial entre pré-vendas e vendas
          </div>
          <p class="description">
            Possuir um vendedor que faz tanto o pré-vendas quanto a venda é improdutivo e ineficaz. Essas tarefas são diferentes, exigindo conhecimentos e técnicas diferentes. Portanto, tenha uma estrutura comercial com pré-vendedores e vendedores.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/guia-do-pre-vendas/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Peça por indicações
          </div>
          <p class="description">
            Não tenha medo de pedir indicações para os seus possíveis clientes. Vendas com origem em indicações, segundo a Hubspot, possuem uma taxa de fechamento de 50% a 70%. Além disso, 83% dos seus clientes estão dispostos a fazer uma recomendação do seu produto logo após a venda.
          </p>
        </div>

        <div class="accordion-container-item">
          <div class="title">
            Traga tecnologia para sua prospecção
          </div>
          <p class="description">
            Ferramentas como o Consulta CNPJ Pro são responsáveis por trazer listas de prospects segmentadas por região, segmento, CNPJ, capital social e outros. Por isso, trazer ferramentas que aumentem a performance da sua prospecção e algo fundamental para o sucesso da sua operação.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/o-que-e-o-consulta-cnpj-e-como-usar/">Saiba Mais
            </a>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="border-b mt-30">
      <div class="accordion-container" v-b-toggle.accordion-2>
        <div class="flex align-center justify-between">
          <div class="title font-averta">
            como aumentar sua conversão de prospects para leads
          </div>
          <div class="icon _open">
            <i class="ri-add-line"></i>
          </div>
          <div class="icon _close">
            <i class="ri-close-fill"></i>
          </div>
        </div>
        <div class="summary">
          <p>
            Melhorar sua conversão de prospects para leads é uma das melhores formas de gerar mais vendas. Conheça como melhorar sua eficiência nesse processo.
          </p>
        </div>
      </div>
      <b-collapse
        id="accordion-2"
        class="collapse-item"
        accordion="my-accordion"
        role="tabpanel">
        <div class="accordion-container-item mb-20">
          <div class="title">
            Eficiência na ligação do pré-vendas
          </div>
          <p class="description">
            Outro ponto com impacto direto no processamento de prospects para leads é a eficiência geral dos seus SDRs, quantas ligações eles fazem por dia? Quanto tempo eles ficam nessas ligações? Querendo ou não, é necessário um certo volume para tornar sua operação de prospecção eficiente.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/cold-calling-2-0/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Quantidade de pontos de contato
          </div>
          <p class="description">
            Segundo a RAIN Group, são necessários, em média, 8 pontos de contato para agendar uma reunião com um prospect.
          </p>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Fluxos de cadência mistos
          </div>
          <p class="description">
            Fluxos de cadência mistos, responsáveis por mesclarem diversos canais de comunicação durante um período de tempo convertem 5 vezes mais.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/fluxo-de-cadencia/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Volume das listas de contatos
          </div>
          <p class="description">
            É necessário compreender se a quantidade de prospects em cada lista de contatos não está muito alta, saturando o processamento do seu pré-vendas.
          </p>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Segmente suas listas com base nos seus resultados
          </div>
          <p class="description">
            Olhe para o seu histórico de vendas e busque criar listas de contatos com empresas que tenham um perfil semelhante a quem já comprou de você.
          </p>
        </div>

        <div class="accordion-container-item">
          <div class="title">
            E-mails Frios
          </div>
          <p class="description">
            Os cold mails têm o objetivo de levantar o interesse do prospect na sua solução, por isso, estabeleça uma relação clara com uma dor do possível cliente e que você pode ajudar ele nesse problema.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/como-escrever-cold-mails-que-vendem/">Saiba Mais
            </a>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="border-b mt-30">
      <div class="accordion-container" v-b-toggle.accordion-3>
        <div class="flex align-center justify-between">
          <div class="title font-averta">
            como gerar mais leads
          </div>
          <div class="icon _open">
            <i class="ri-add-line"></i>
          </div>
          <div class="icon _close">
            <i class="ri-close-fill"></i>
          </div>
        </div>
        <div class="summary">
          <p>
            Existem várias maneiras de aumentar sua geração de leads. Aprenda como gerar mais leads.
          </p>
        </div>
      </div>
      <b-collapse
        id="accordion-3"
        class="collapse-item"
        accordion="my-accordion"
        role="tabpanel">
        <div class="accordion-container-item mb-20">
          <div class="title">
            Utilize a mídia paga
          </div>
          <p class="description">
            Um dos canais para gerar mais leads para o seu negócio é a mídia paga. Esse canal é uma excelente maneira de encontrar seu público-alvo. Afinal, quem não está nas redes sociais hoje em dia?
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/manual-facebook-ads/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Tenha um construtor de Landing Pages
          </div>
          <p class="description">
            Possuir um local onde seu potencial cliente possa fornecer os dados dele em troca de algo é o princípio da geração de leads. Mas, como a maioria, saber codificar para criar qualquer página online é inviável, ter um construtor de landing pages é algo que facilita e muito o seu trabalho.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/landing-pages-o-que-e-e-qual-importancia/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Construa sua presença nas redes sociais
          </div>
          <p class="description">
            Segundo a Super Office, 84% dos executivos seniores utilizam as redes sociais como suporte para o momento de decisão de compra. Se você é do mercado B2B, foque em construir um perfil forte no LinkedIn, mostrando seus cases, conhecimentos e sua autoridade no assunto.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/como-vender-nas-redes-sociais/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Tenha um live chat no seu site
          </div>
          <p class="description">
            Possuir um chat no seu site aumenta em 20% as taxas de conversão segundo estudo da Invesp. Além de ser uma excelente maneira de sanar as dúvidas dos seus potenciais clientes no momento de decisão de compra.
          </p>
        </div>
      </b-collapse>
    </div>

    <div class="border-b mt-30">
      <div class="accordion-container" v-b-toggle.accordion-4>
        <div class="flex align-center justify-between">
          <div class="title font-averta">
            como aumentar sua conversão de leads para oportunidades
          </div>
          <div class="icon _open">
            <i class="ri-add-line"></i>
          </div>
          <div class="icon _close">
            <i class="ri-close-fill"></i>
          </div>
        </div>
        <div class="summary">
          <p>
            Transforme leads em oportunidades qualificadas. Trazer eficiência na passagem de leads para oportunidades pode transformar a forma como você vende. Aprenda a aperfeiçoar sua taxa de conversão de leads em oportunidades.
          </p>
        </div>
      </div>
      <b-collapse
        id="accordion-4"
        class="collapse-item"
        accordion="my-accordion"
        role="tabpanel">
        <div class="accordion-container-item mb-20">
          <div class="title">
            Construção de interesse
          </div>
          <p class="description">
            Um dos motivos que reduzem a taxa de conversão de lead para oportunidade é a má construção de interesse por parte do pré-vendedor. É preciso identificar a dor do cliente e atrelar a resolução dessa dor a um benefício do seu produto
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/como-encontrar-a-dor-do-cliente/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Mapear objetivos
          </div>
          <p class="description">
            Algumas técnicas são utilizadas para compreender os objetivos dos clientes e como sua solução pode impactá-los. Conheça o GPCTBA C&I
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/o-que-e-gpctba-ci/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Apresente benefícios e não funcionalidades
          </div>
          <p class="description">
            Na área de vendas sempre é falado para abordar benefícios e não funcionalidades, mas, muitas vezes, não fica claro o que é o que. Entenda a diferença entre benefícios, funcionalidades e vantagens.
          </p>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Técnicas de ligação
          </div>
          <p class="description">
            Segundo a Gong, os melhores vendedores são aqueles que abordam 15 tópicos em uma ligação de 45 minutos. Ou seja, um bom framework de ligação é você utilizar tópicos qualificatórios em 50% da ligação, 20% sobre funcionalidades e 30% para próximos passos.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/tecnicas-de-vendas-telefone/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Construa rapport
          </div>
          <p class="description">
            A construção de rapport é a fundação dos relacionamentos com base em harmonia e afinidade. Entenda como construir um rapport em vendas.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/rapport-em-vendas-o-que-e-e-como-fazer/">Saiba Mais
            </a>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="mb-50 mt-30">
      <div class="accordion-container" v-b-toggle.accordion-5>
        <div class="flex align-center justify-between">
          <div class="title font-averta">
            como aumentar sua conversão de oportunidades para vendas
          </div>
          <div class="icon _open">
            <i class="ri-add-line"></i>
          </div>
          <div class="icon _close">
            <i class="ri-close-fill"></i>
          </div>
        </div>
        <div class="summary">
          <p>
            Não existe forma melhor de aumentar o seu faturamento do que melhorando a conversão de oportunidades em vendas. É o momento onde o resultado acontece. Conheça formas de trazer eficiência para sua gestão de oportunidades.
          </p>
        </div>
      </div>
      <b-collapse
        id="accordion-5"
        class="collapse-item"
        accordion="my-accordion"
        role="tabpanel">
        <div class="accordion-container-item mb-20">
          <div class="title">
            Identifique as objeções em vendas
          </div>
          <p class="description">
            As objeções em vendas são toda e qualquer dúvida por parte do seu possível cliente que levam ele a não fechar um negócio com você. Entenda quais são e como contornar as principais objeções.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/objecoes-em-vendas/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Reconheça o perfil comportamental de cada possível cliente
          </div>
          <p class="description">
            Cada cliente possui um perfil, e esse perfil é responsável por mostrar como ele provavelmente vai agir em diversas situações. Conhecer os perfis comportamentais leva o vendedor a estipular estratégias de comunicação mais eficientes.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/perfil-de-cliente-e-como-vender-para-eles/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Utilize o BANT
          </div>
          <p class="description">
            O BANT é uma metodologia que foi aplicada pela primeira vez na IBM e que determina que um potencial cliente está pronto para comprar se atender 3 a 4 de seus itens. Serve para ajudar a equipe comercial a se concentrar nas oportunidades que realmente têm interesse e precisam da solução oferecida.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/o-que-e-a-metodologia-bant-sales/">Saiba Mais
            </a>
          </div>
        </div>

        <div class="accordion-container-item mb-20">
          <div class="title">
            Técnicas de negociação
          </div>
          <p class="description">
            O momento da negociação é um dos mais críticos na jornada de compra de um cliente. Saber como negociar é fundamental para o sucesso de qualquer operação.
          </p>
          <div class="link">
            <a
              class="font-averta"
              target="_blank"
              href="https://leads2b.com/blog/tecnicas-de-negociacao/">Saiba Mais
            </a>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
};
</script>
<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.accordion {
  &-container {
    outline: none;
    margin-bottom: 45px;
    .title {
      font-size: 1.625rem;
    }
    .icon {
      i {
        color: $bg-light-green;
        font-weight: bold;
      }
    }
    .summary {
      width: 90%;
      p {
        color: $primary-grey;
      }
    }
    &-item {
      .title, p {
        color: $primary-color;
      }
      .title {
        font-weight: bold;
      }
      a {
        color: $bg-light-green;
      }
      .description {
        margin-bottom: 0;
      }
    }
  }
  .border-b {
    border-bottom: 1px solid $bg-dark-grey;
  }
  .collapse-item {
    padding-bottom: 30px;
  }
  .collapsed {
    ._close {
      display: none;
    }
    ._open {
      display: block;
    }
  }
  .not-collapsed {
    ._close {
      display: block;
    }
    ._open {
      display: none;
    }
  }
}
</style>
