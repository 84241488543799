<template>
  <div class="container quiz">
    <!-- Step 0 -->
    <div v-if="step === '0'" class="row">
      <div class="col-12 col-lg-6">
        <h3 class="mb-0">crie e aprimore o seu</h3>
        <h1 class="mb-20">funil de vendas</h1>
        <p class="font-normal mb-45 w-90"> Quer descobrir o máximo do seu potencial em vendas? Obtenha resultados incríveis em vendas e bata as tão desejadas metas!</p>
        <button class="bg-yellow" v-on:click="$emit('startquiz')">Começar Agora <i class="ri-arrow-right-line"></i></button>
        <div class="flex flex-column warnings mt-45">
          <span class="font-w600 mb-10 color-grey">Descubra como extrair o melhor de cada etapa de</span>
          <h4 class="font-w600 mb-40">Vendas e Marketing</h4>
          <div class="flex align-center">
            <i class="ri-award-line color-green"></i>
            <span class="font-averta color-green">E ainda ganhe prêmios a cada etapa!</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5 offset-lg-1 clip-path">
        <ul class="mt-50">
          <li class="bg-dark-blue">prospect</li>
          <li class="bg-blue w-80">lead</li>
          <li class="bg-light-blue w-60">oportunidade</li>
          <li class="bg-light-green w-40">venda</li>
        </ul>
      </div>
    </div>

    <!-- Step 1 -->
    <div v-if="step === '1'" class="row step step1">
      <div class="col-12 col-lg-6">
        <h3>criando o seu</h3>
        <h1 class="mb-20">funil de vendas</h1>
        <a
          class="flex align-center mb-20 back"
          href="/">
          <div class="back-container">
            <i class="ri-arrow-left-line mr-10"></i> Voltar
          </div>
        </a>
        <p class="font-normal mb-30 w-90"> Olá! Aumentar a performance das equipes de vendas é o nosso principal desafio. Se você também acredita que é possível vender mais, ótimo, já encontramos algo em comum!</p>
        <p class="font-normal mb-30 w-90"> Projetamos essa ferramenta para você construir o seu funil de vendas ideal!</p>
        <p class="font-normal mb-30 w-90"> Assim você terá um poderoso aliado para planejar estratégias e ficar próximo de bater suas metas ou até mesmo aumentá-las.</p>
        <p class="font-normal mb-30 w-90"> Iremos te ajudar a construir do zero um funil de vendas com insights para você aumentar cada etapa das suas vendas.</p>
        <button class="bg-transparent" v-on:click="setStep('2')">Vamos Começar! <i class="ri-arrow-right-line"></i></button>
      </div>
      <div class="col-12 col-lg-5 offset-lg-1 clip-path opacity3 d-none d-lg-block">
        <ul class="mt-50">
          <li class="bg-light-grey"></li>
          <li class="bg-light-grey w-80"></li>
          <li class="bg-light-grey w-60"></li>
          <li class="bg-light-grey w-40"></li>
        </ul>
      </div>
    </div>

    <!-- Step 2 -->
    <Step
      v-if="step === '2'"
      class="step step2"
      next-step="3"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep2 = $event">
      <template slot="topTitle">
        <div class="col-12">
          <h1 class="mb-20">quanto você quer vender?</h1>
        </div>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90"> Queremos que você alcance o seu objetivo de vendas! Para isso precisamos saber quanto você precisa vender para bater as suas metas.</p>
        <p class="mt-30">Digite aproximadamente o <strong>valor da sua meta de vendas</strong> em reais</p>
      </template>
      <template slot="callTopAction">
        <i class="ri-award-line"></i><span class="ml-10 font-w600">Hey! Agora você pode acessar a tela de Dashboards na Leads2b!</span></template>
      <template slot="lines">
        <li class="bg-light-grey"></li>
        <li class="bg-light-grey w-80"></li>
        <li class="bg-light-grey w-60"></li>
        <li class="bg-light-grey w-40"></li>
      </template>
    </Step>

    <!-- Step 3 -->
    <Step
      v-if="step === '3'"
      class="step step3"
      next-step="4"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep3 = $event">
      <template slot="title">
        <h3>conhecendo</h3>
        <h1 class="mb-20">sua equipe</h1>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90">Para começar, é importante entender quantas pessoas estão trabalhando na sua operação de vendas. Assim, iremos projetar se os resultados da sua equipe estão dentro das possibilidades do funil.</p>
        <p class="mt-30">Digite aproximadamente quantos <strong>vendedores e pré-vendedores</strong> trabablham na sua equipe</p>
      </template>
      <template slot="callTopAction">
        <i class="ri-award-line"></i><span class="ml-10 font-w600">Você acaba de ganhar um usuário gratuito na Leads2b!</span></template>
      <template slot="lines">
        <li class="bg-light-grey"></li>
        <li class="bg-light-grey w-80"></li>
        <li class="bg-light-grey w-60"></li>
        <li class="bg-light-grey w-40"></li>
      </template>
    </Step>

    <!-- Step 4 -->
    <div v-if="step === '4'" class="row step step4">
      <div class="col-12 col-lg-6">
        <h3>entendendo o seu</h3>
        <h1 class="mb-20">funil de vendas</h1>
        <a
          class="flex align-center mb-20 back"
          v-on:click="setStep('3')"
          href="#">
          <div class="back-container">
            <i class="ri-arrow-left-line mr-10"></i> Voltar
          </div>
        </a>
        <p class="font-normal mb-30 w-90">Agora vamos analisar a sua situação atual de vendas.</p>
        <p class="font-normal mb-30 w-90">Assim, iremos traçar estratégias de melhoria em cada etapa do funil para você atingir a sua meta!</p>
        <button class="bg-transparent" v-on:click="setStep('5')">Vamos Começar! <i class="ri-arrow-right-line"></i></button>
      </div>
      <div class="col-12 col-lg-5 offset-lg-1 clip-path opacity3 d-none d-lg-block">
        <ul class="mt-50">
          <li class="bg-light-grey"></li>
          <li class="bg-light-grey w-80"></li>
          <li class="bg-light-grey w-60"></li>
          <li class="bg-light-grey w-40"></li>
        </ul>
      </div>
    </div>

    <!-- Step 5  Vendas-->
    <Step
      v-if="step === '5'"
      class="step step5"
      next-step="6"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep5 = $event">
      <template slot="topTitle">
        <div class="col-12">
          <h1 class="mb-20">vendas</h1>
        </div>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90">As vendas são basicamente a quantidade de negócios fechados em um período determinado.</p>
        <p class="font-w300 mb-30 w-90">É o objetivo final de cada negociação.</p>
        <p class="mt-30">Digite aproximadamente quantas <span class="color-light-green"><strong>vendas</strong></span> a sua equipe realiza em média por mês?</p>
      </template>
      <template slot="callTopAction">
        <i class="ri-award-line"></i><span class="ml-10 font-w600">Parabéns! Você acaba de ganhar o módulo de clientes na Leads2b!</span></template>
      <template slot="lines">
        <li class="bg-light-grey"></li>
        <li class="bg-light-grey w-80"></li>
        <li class="bg-light-grey w-60"></li>
        <li class="bg-light-green w-40"></li>
      </template>
    </Step>

    <!-- Step 6 Oportunidades -->
    <Step
      v-if="step === '6'"
      class="step step6"
      next-step="7"
      v-bind:previousValue="valueStep5"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep6 = $event">
      <template slot="topTitle">
        <div class="col-12">
          <h1 class="mb-20 break-all">oportunidades</h1>
        </div>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90">Oportunidades de vendas são contatos que realmente passaram para uma etapa de interesse de compra. É o momento onde realmente aparece a figura do vendedor que irá tirar dúvidas e apresentar o produto ou serviço.</p>
        <p class="font-w300 mb-30 w-90">Geralmente na etapa de oportunidade acontecem visitas comerciais, negociações, envio de orçamento, follow-ups, entre outras ações.</p>
        <p class="mt-30">Digite aproximadamente quantas <span class="color-light-blue"><strong>oportunidades</strong></span> você gera em média por mês</p>
      </template>
      <template slot="callTopAction">
        <i class="ri-award-line"></i><span class="ml-10 font-w600">Você acaba de ganhar um CRM completo para acompanhar as atividades do seu time!</span></template>
      <template slot="lines">
        <li class="bg-light-grey"></li>
        <li class="bg-light-grey w-80"></li>
        <li class="bg-light-blue w-60"></li>
        <li class="bg-light-grey w-40"></li>
      </template>
    </Step>

    <!-- Step 7 Leads -->
    <Step
      v-if="step === '7'"
      class="step step7"
      next-step="8"
      v-bind:previousValue="valueStep6"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep7 = $event">
      <template slot="topTitle">
        <div class="col-12">
          <h1 class="mb-20">leads</h1>
        </div>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90">Leads são possíveis clientes que já interagiram com a sua marca, seja por e-mail, telefone ou pelo site.</p>
        <p class="font-w300 mb-30 w-90">Na prospecção ativa, um lead é criado quando ele responde um e-mail ou aceita conversar por telefone.</p>
        <p class="font-w300 mb-30 w-90">Você pode também acrescentar os leads gerados por Marketing Digital, seja aqueles que baixaram algum material (e-book, infográfico, ou outros) ou até aqueles que solicitaram um contato comercial.</p>
        <p class="mt-30">Digite aproximadamente quantos <span class="color-blue"><strong>leads</strong></span> você gera em média por mês</p>
      </template>
      <template slot="callTopAction">
        <i class="ri-award-line"></i><span class="ml-10 font-w600">Parabéns! Você acaba de ganhar a gestão de leads na sua platafoma gratuita!</span></template>
      <template slot="lines">
        <li class="bg-light-grey"></li>
        <li class="bg-blue w-80"></li>
        <li class="bg-light-grey w-60"></li>
        <li class="bg-light-grey w-40"></li>
      </template>
    </Step>

    <!-- Step 8 Prospect -->
    <Step
      v-if="step === '8'"
      class="step step8"
      next-step="9"
      v-bind:previousValue="valueStep7"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep8 = $event">
      <template slot="topTitle">
        <div class="col-12">
          <h1 class="mb-20">prospect</h1>
        </div>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90">Prospects são possíveis clientes que pertencem muito possivelmente ao seu perfil de cliente ideal.</p>
        <p class="font-w300 mb-30 w-90">Na prospecção ativa, são as empresas que você decide prospectar. Um exemplo são as listas de empresas ou os contatos abordados no LinkedIn. Ou você pode colocar também quantas empresas você conhece em sua região das quais poderiam ser seu cliente.</p>
        <p class="mt-30">Digite aproximadamente quantos <span class="color-blue"><strong>prospect</strong></span> você gera em média por mês</p>
      </template>
      <template slot="callTopAction">
        <i class="ri-award-line"></i><span class="ml-10 font-w600">Agora sim! Você acaba de ganhar 30 créditos gratuitos para gerar prospects na plataforma Leads2b.</span></template>
      <template slot="lines">
        <li class="bg-dark-blue"></li>
        <li class="bg-light-grey w-80"></li>
        <li class="bg-light-grey w-60"></li>
        <li class="bg-light-grey w-40"></li>
      </template>
    </Step>

    <!-- Step 9 ticket médio -->
    <Step
      v-if="step === '9'"
      class="step step9"
      next-step="10"
      v-on:setNextStep="setStep($event)"
      v-on:setBacktStep="setStep($event)"
      v-on:setCurrentValue="valueStep9 = $event">
      <template slot="topTitle">
        <div class="col-12">
          <h1 class="mb-20">ticket médio</h1>
        </div>
      </template>
      <template slot="description">
        <p class="font-w300 mb-30 w-90">Para finalizarmos o cálculo do seu funil de vendas, informe também o seu ticket médio. Com este valor iremos projetar como ficará a sua receita caso aumente suas conversões e abastecimento.</p>
        <p class="font-w300 mb-30 w-90">Para calcular o seu <strong>ticket médio</strong>, analise o seu faturamento total e divida pela quantidade de vendas realizadas. Por exemplo, um faturamento de <strong>R$ 15.000</strong> realizado graças a <strong>15 vendas</strong> geram um ticket de <strong>R$ 1.000</strong>.</p>
        <p class="mt-30">Digite aproximadamente o seu <strong>ticket médio</strong> em reais</p>
      </template>
      <template slot="lines">
        <li class="bg-dark-blue"></li>
        <li class="bg-blue w-80"></li>
        <li class="bg-light-blue w-60"></li>
        <li class="bg-light-green w-40"></li>
      </template>
    </Step>

    <Result
      v-show="step === '10'"
      v-bind:p-leads="valueStep7"
      v-bind:p-sales-amount="valueStep2"
      v-bind:p-opportunity="valueStep6"
      v-bind:p-prospect="valueStep8"
      v-bind:p-sales="valueStep5"
      v-bind:p-team="valueStep3"
      v-bind:p-ticket-medium="valueStep9"
      v-bind:has-result="hasResult"
      class="row step step10">
    </Result>
  </div>
</template>

<script>
import Step from './Step';
import Result from './Result';

export default {
  name: 'Quiz',
  props: {
    step: {
      type: String,
    },
    hasResult: {
      type: Boolean,
      default: false,
    },
  },
  component: { Step, Result },
  data() {
    return {
      valueStep2: '', // how much do you want to sell
      valueStep3: '', // your team
      valueStep5: '', // sales
      valueStep6: '', // opportunities
      valueStep7: '', // leads
      valueStep8: '', // prospect
      valueStep9: '', // ticket médio
      form: {
        name: '',
        email: '',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
    };
  },
  methods: {
    setStep(value) {
      this.$emit('changeStep', value.toString());
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.clip-path {
  &::after {
    content: '';
    clip-path: ellipse(50% 37% at 50% 50%);
    background-color: $bg-blue-300;
    width: 130%;
    height: 100%;
    top: -50px;
    left: 0;
    @media (min-width: 992px) {
      position: absolute;
      width: 170%;
      top: -85px;
      left: -35px;
    }
    @media (min-width: 1201px) {
      width: 100%;
      top: -50px;
      left: 0;
    }
  }
  &.opacity3 {
    &::after {
      opacity: 0.3;
    }
  }
}
.warnings {
  span {
    font-size: 1.375rem;
  }
  i {
    font-size: 6.25rem;
  }
}
.quiz {
  padding-bottom: 80px;
  p {
    font-size: 1.25rem;
    line-height: 32px;
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (min-width: 992px) {
      position: absolute;
      z-index: 5;
    }
    li {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 80px;
      align-items: center;
      margin-bottom: 25px;
      padding-right: 20px;
      color: $white;
      font-weight: bold;
      font-size: 18px;
      @media (min-width: $sm-breakpoint) {
        font-size: 24px;
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    input {
      border: 3px solid #2D3135;
      padding: 23px;
      margin-bottom: 20px;
      font-size:  1rem;
      color: $txt-input-grey;
    }
    .btns {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      @media (min-width: $md-breakpoint) {
        flex-direction: initial;
      }
      button, a {
        width: 100%;
        margin-bottom: 20px;
        @media (min-width: $md-breakpoint) {
          width: 50%;
        }
      }
      a {
        color: $txt-link-grey;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      button {
        font-family: $font-inter;
        font-size:  1rem;
      }
    }
  }
  .step4 {
    @media (min-width: $lg-breakpoint) {
      height: 80vh;
    }
    .clip-path {
      height: 100%;
      &::after {
        height: 90%;
      }
    }
  }
  .back {
    color: $primary-color;
    text-decoration: none;
    &-container {
      display: flex;
      &:hover {
        border-bottom: 1px solid $primary-color;
      }
    }
  }
}
</style>
