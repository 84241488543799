<template>
  <div id="app">
    <header>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <a href="https://leads2b.com/">
              <img alt="Leads2b" src="./assets/image/logo-leads2b.svg">
            </a>
          </div>
        </div>
      </div>
    </header>
    <Quiz
      v-on:startquiz="startQuiz()"
      v-bind:step="step"
      v-on:changeStep="step = $event"
      v-bind:has-result="hasResult"
    />
    <div v-if="!quiz" class="learn-commercial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3>aprenda como estruturar o seu setor comercial</h3>
            <div class="actions">
              <div class="item">
                <i class="ri-stackshare-line"></i>
                <h4 class="font-w600">Estratégias de <br> Negócio</h4>
              </div>
              <div class="item">
                <i class="ri-user-add-line"></i>
                <h4 class="font-w600">Aumentar <br> conversões</h4>
              </div>
              <div class="item">
                <i class="ri-lightbulb-flash-line"></i>
                <h4 class="font-w600">Pontos de Melhoria</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer v-if="!quiz || hasResult">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 v-if="!hasResult">conheça como aprimorar o seu funil</h3>
            <h3 v-else class="mb-30">quer chegar a sua máxima performance?</h3>
            <p
              v-if="hasResult"
              class="mb-20">Conheça agora como ajudamos empresas como a sua a aumentar expressivamente seus resultados.
            </p>
            <button
              v-if="!hasResult"
              v-on:click="startQuiz()"
              class="bg-yellow">Começar Agora <i class="ri-arrow-right-line"></i>
            </button>
            <button
              v-else
              class="bg-yellow mt-30">
              <a href="https://leads2b.com/conheca-agora/">Começar Agora</a> <i class="ri-arrow-right-line"></i>
            </button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Quiz from './components/Quiz';

export default {
  name: 'App',
  components: { Quiz },
  data() {
    return {
      quiz: false,
      step: '0',
      hasResult: false,
    };
  },
  watch: {
    'this.$router.params': {
      immediate: true,
      handler: 'checkForURLParams',
    },
  },
  methods: {
    startQuiz() {
      this.quiz = true;
      this.step = '1';
    },
    checkForURLParams() {
      const params = new URLSearchParams(window.location.search);
      if (params.get('r')) {
        this.step = '10';
        this.quiz = true;
        this.hasResult = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./assets/scss/variables.scss";
#app {
  overflow: hidden;
}
header {
  display: flex;
  padding-top: 35px;
  padding-bottom: 50px;
}
.learn-commercial {
  background-color: $bg-grey;
  padding: 120px 0px;
  h3 {
    padding-bottom: 80px;
  }
  .actions {
    display: flex;
    flex-direction: column;
    @media (min-width: $sm-breakpoint) {
    display: flex;
    flex-direction: initial;
    justify-content: space-evenly;
    }
    .item {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      @media (min-width: $sm-breakpoint) {
        margin-bottom: 0px;
      }
      i {
        font-size: 100px;
        color: $bg-light-green;
        margin-bottom: 20px;
      }
      h4 {
        font-size: 21px;
        text-align: center;
        line-height: 30px;
      }
    }
  }
}
footer {
  background-color: $bg-dark-grey;
  padding-top: 45px;
  padding-bottom: 60px;
  h3 {
    color: $white;
    margin-bottom: 65px;
  }
  button {
    margin: 0 auto;
    a {
      color: $primary-color;
      text-decoration: none;
    }
  }
  p {
    color: $primary-grey;
  }
}
</style>
