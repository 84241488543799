import Vue from 'vue';
import VueInputAutowidth from 'vue-input-autowidth';
import money from 'v-money';
import { BCollapse, VBToggle } from 'bootstrap-vue';
import App from './App';
import './assets/scss/_index.scss';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'remixicon/fonts/remixicon.css';
import Step from './components/Step';
import Result from './components/Result';
import Accordion from './components/Accordion';

Vue.directive('b-toggle', VBToggle);
Vue.use(VueInputAutowidth);
Vue.use(money, { precision: 4 });

Vue.component('b-collapse', BCollapse);
Vue.component(Step.name, Step);
Vue.component(Result.name, Result);
Vue.component(Accordion.name, Accordion);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
