<template>
  <div class="row">
    <slot name="topTitle"></slot>
    <div class="col-12 col-lg-6 mt-30">
      <slot name="title"></slot>
      <div
        class="flex align-center mb-20 back"
        v-on:click="goBack()">
        <i class="ri-arrow-left-line mr-10"></i> Voltar
      </div>
      <slot name="description"></slot>
      <div class="input-container">
        <input
          class="input-auto font-averta"
          v-model="valueStep"
          v-on:keyup.enter="setStep()"
          v-on:keydown="$event.key === '-' ? $event.preventDefault() : null"
          v-autowidth="{maxWidth: '500px', minWidth: '20px', comfortZone: 7}"
          v-money="valueStep !== null ? money : null"
          v-bind:class="{ active: valueStep != 0 }"
        />
        <i
          class="ri-arrow-right-line ml-20"
          v-bind:class="{ active: valueStep != 0 }"
          v-on:click="valueStep != 0 ? setStep() : ''">
        </i>
      </div>
      <div v-if="(nextStep === '7' || nextStep === '8' || nextStep === '9') && !hasAlert" class="row">
        <div class="alert">Este valor deve ser maior do que o digitado no passo anterior.</div>
      </div>
      <div class="check mt-40 mb-40">
        <div v-show="valueStep != 0" class="feedback flex color-green">
          <slot name="callTopAction"></slot>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 offset-lg-1 clip-path opacity3 d-none d-lg-block">
      <ul>
        <slot name="lines"></slot>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    nextStep: {
      type: String,
      required: true,
    },
    previousValue: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      valueStep: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
    };
  },
  computed: {
    hasAlert() {
      if (this.nextStep === '7' || this.nextStep === '8' || this.nextStep === '9') {
        return this.valueStepFormater > this.previousValueFormater;
      }
      return false;
    },
    valueStepFormater() {
      return parseInt(this.valueStep.replace(/[^\d]+/g, ''), 10);
    },
    previousValueFormater() {
      return parseInt(this.previousValue.replace(/[^\d]+/g, ''), 10);
    },
  },
  methods: {
    setStep() {
      if (this.valueStepFormater > this.previousValueFormater) {
        this.$emit('setNextStep', this.nextStep);
        this.$emit('setCurrentValue', this.valueStep);
        this.valueStep = '';
      }
    },
    goBack() {
      this.$emit('setBacktStep', this.nextStep - 2);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.clip-path {
  &::after {
    content: '';
    clip-path: ellipse(50% 37% at 50% 50%);
    background-color: $bg-blue-300;
    width: 130%;
    height: 100%;
    top: -50px;
    left: 0;
    @media (min-width: 992px) {
      position: absolute;
      width: 170%;
      top: -85px;
      left: -35px;
    }
    @media (min-width: 1201px) {
      width: 100%;
      top: -50px;
      left: 0;
    }
  }
  &.opacity3 {
    &::after {
      opacity: 0.3;
    }
  }
}

p {
  font-size: 1.25rem;
  line-height: 32px;
}
ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (min-width: 992px) {
    position: absolute;
    z-index: 5;
  }
  li {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 80px;
    align-items: center;
    margin-bottom: 25px;
    padding-right: 20px;
    color: $white;
    font-weight: bold;
    font-size: 18px;
    @media (min-width: $sm-breakpoint) {
      font-size: 24px;
    }
  }
}
.input {
  &-container {
    display: flex;
    align-items: center;
    i {
      color: $txt-placeholder-grey;
      font-size: 2.1875rem;
      margin-top: 5px;
      &.active {
        color: $bg-dark-grey;
        cursor: pointer;
      }
    }
  }
  &-auto {
    border: none;
    outline:none;
    font-size: 4rem;
    border-bottom: 2px solid;
    color: $txt-placeholder-grey;
    &.active {
      border-bottom: none;
      color: $bg-dark-grey;
    }
  }
}
input[type=checkbox] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.feedback {
  display: flex;
  align-items: center;
  i {
    font-size: 30px;
  }
}
.step {
  @media (min-width: $lg-breakpoint) {
    height: 80vh;
  }
  .clip-path {
    height: 100%;
    &::after {
      height: 600px;
    }
  }
}
.step6, .step7, .step8, .step9 {
  height: auto;
}
.alert {
  color: $alert;
}
.back {
  cursor: pointer;
}
</style>
