<template>
  <div>
    <div class="col-12">
      <h1 class="mb-20">resultado</h1>
    </div>
    <div class="col-12 col-lg-6 mt-30">
      <p v-if="!showResult" class="font-w300 mb-30 w-90">Para finalizar e receber suas bonificações, digite o seu e-mail comercial e veja o resultado!</p>
      <p v-else class="font-w300 mb-30 w-90">Confira agora o seu funil de vendas atual e como aperfeiçoar os seus resultados em vendas!</p>
      <form
        v-if="!showResult"
        method="post"
        v-on:submit.prevent="formSubmission"
        v-bind:action="actionUrl">
        <input
          type="text"
          name="name"
          v-model="form.name"
          placeholder="digite seu nome"
          required="true"
        >
        <input
          type="email"
          name="email"
          v-model="form.email"
          placeholder="digite seu melhor e-mail"
          required="true"
        >
        <input
          type="hidden"
          name="leads"
          v-bind:value="pLeads"
        >
        <input
          type="hidden"
          name="opportunity"
          v-bind:value="pOpportunity"
        >
        <input
          type="hidden"
          name="prospect"
          v-bind:value="pProspect"
        >
        <input
          type="hidden"
          name="sales"
          v-bind:value="pSales"
        >
        <input
          type="hidden"
          name="salesAmount"
          v-bind:value="pSalesAmount"
        >
        <input
          type="hidden"
          name="team"
          v-bind:value="pTeam"
        >
        <input
          type="hidden"
          name="ticketMedium"
          v-bind:value="pTicketMedium"
        >
        <div class="btns mb-40">
          <div class="btns-submit mr-20">
            <input
              type="submit"
              class="bg-transparent font-w300 mb-0"
              value="receber o prêmio e continuar"
            />
            <i class="ri-arrow-right-line"></i>
          </div>
          <a href="#" v-on:click="goToResult()">não quero receber os prêmios</a>
        </div>
        <div class="check">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked='false'
            v-model="enableTerms"
          />
          <label for="terms">Eu aceito receber conteúdo educacional e promocional relacionado a vendas e a política de privacidade.</label>
        </div>
      </form>
      <div v-else class="copy flex flex-column w-90">
        <label class="mb-10">Salve o seu resultado</label>
        <div class="group-icon">
          <input
            type="text"
            ref="copy"
            v-bind:value="form.result"
            placeholder="Funil.com/RESULTADO"
          >
          <i
            v-on:click="copy()"
            class="ri-file-copy-line"
            title="Copiar">
          </i>
        </div>
        <div class="social flex flex-column mt-40">
          <span class="mb-10">Compartilhe nas redes sociais o <strong>funil.com</strong></span>
          <ul>
            <li>
              <a v-bind:href="shareFacebook" target="_blank">
                <i class="ri-facebook-box-fill"></i>
              </a>
            </li>
            <li>
              <a v-bind:href="shareLinkedin" target="_blank">
                <i class="ri-linkedin-box-fill"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-bind:class="{opacity : showResult}" class="col-12 col-lg-5 offset-lg-1 clip-path opacity3 d-none d-lg-block">
      <ul>
        <li class="bg-dark-blue"></li>
        <li class="bg-blue w-80"></li>
        <li class="bg-light-blue w-60"></li>
        <li class="bg-light-green w-40"></li>
      </ul>
    </div>
    <div v-if="showResult" class="funis mt-100">
      <div class="col-12 col-lg-4 text-center mt-30">
        <div class="title font-w700 mb-30">funil atual</div>
        <div class="calc prospect">
          <div class="calc-title font-w700 mb-15">prospects</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">{{ prospect }}</div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round((leads / prospect) * 100) }}%</div>
        </div>

        <div class="calc leads mt-30">
          <div class="calc-title font-w700 mb-15">leads</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">{{ leads }}</div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round((opportunity / leads) * 100) }}%</div>
        </div>

        <div class="calc opportunities mt-30">
          <div class="calc-title font-w700 mb-15">oportunidades</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">{{ opportunity }}</div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round((sales / opportunity) * 100) }}%</div>
        </div>

        <div class="calc sales mt-30">
          <div class="calc-title font-w700 mb-15">vendas</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">{{ sales }}</div>
        </div>

        <div class="calc total mt-40">
          <div class="calc-title font-w700 mb-15">faturamento</div>
          <input
            class="calc-box h-72 mb-20 flex align-center justify-center font-w600 font-averta b-none"
            disabled
            v-bind:value="cResultCurrentFunnel"
            v-money="cResultCurrentFunnel !== null ? money : null"
            v-bind:class="{ active: cResultCurrentFunnel != 0 }"
          />
        </div>
      </div>
      <div class="col-12 col-lg-4 text-center mt-30">
        <div class="title font-w700 mb-30">capacidade do time</div>
        <div class="calc prospect">
          <div class="calc-title font-w700 mb-15">prospects</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">
            {{
              Math.round((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01) / ((opportunity / leads) + 0.02) / ((leads / prospect) + 0.02)) + 250 * team
            }}
          </div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round(((leads / prospect) + 0.05) * 100) }}%</div>
        </div>

        <div class="calc leads mt-30">
          <div class="calc-title font-w700 mb-15">leads</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">
            {{
              Math.round(((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01) / ((opportunity / leads) + 0.02) / ((leads / prospect) + 0.02) + 250 * team)
                * ((leads / prospect) + 0.05))
            }}
          </div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round(((opportunity / leads) + 0.05) * 100) }}%</div>
        </div>

        <div class="calc opportunities mt-30">
          <div class="calc-title font-w700 mb-15">oportunidades</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">
            {{
              Math.round(((opportunity / leads) + 0.05)
                * ((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01) / ((opportunity / leads) + 0.02) / ((leads / prospect) + 0.02) + 250 * team)
                * ((leads / prospect) + 0.05))
            }}
          </div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round(((sales / opportunity) + 0.02) * 100)}}%</div>
        </div>

        <div class="calc sales mt-30">
          <div class="calc-title font-w700 mb-15">vendas</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">
            {{
              Math.round(((opportunity / leads) + 0.05)
                * ((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01) / ((opportunity / leads) + 0.02) / ((leads / prospect) + 0.02) + 250 * team)
                * ((leads / prospect) + 0.05) * ((sales / opportunity) + 0.02))
            }}
          </div>
        </div>

        <div class="calc total mt-40">
          <div class="calc-title font-w700 mb-15">faturamento</div>
          <input
            class="calc-box h-72 mb-20 flex align-center justify-center font-w600 font-averta b-none"
            disabled
            v-bind:value="cResultTeamCapacity"
            v-money="cResultTeamCapacity !== null ? money : null"
            v-bind:class="{ active: cResultTeamCapacity != 0 }"
          />
        </div>
      </div>
      <div class="col-12 col-lg-4 text-center mt-30">
        <div class="title font-w700 mb-30">meta</div>
        <div class="calc prospect">
          <div class="calc-title font-w700 mb-15">prospects</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">
            {{ Math.round((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01) / ((opportunity / leads) + 0.02) / ((leads / prospect) + 0.02)) }}
          </div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round(((leads / prospect) + 0.02) * 100) }}%</div>
        </div>

        <div class="calc leads mt-30">
          <div class="calc-title font-w700 mb-15">leads</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">
            {{ Math.round((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01) / ((opportunity / leads) + 0.02)) }}
          </div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round(((opportunity / leads) + 0.02) * 100) }}%</div>
        </div>

        <div class="calc opportunities mt-30">
          <div class="calc-title font-w700 mb-15">oportunidades</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">{{ Math.round((salesAmount / ticketMedium) / ((sales / opportunity) + 0.01)) }}</div>
          <div class="calc-percent flex align-center justify-center">{{ Math.round(((sales / opportunity) + 0.01) * 100) }}%</div>
        </div>

        <div class="calc sales mt-30">
          <div class="calc-title font-w700 mb-15">vendas</div>
          <div class="calc-box mb-20 flex align-center justify-center font-w600">{{ Math.round(salesAmount / ticketMedium) }}</div>
        </div>

        <div class="calc total mt-40">
          <div class="calc-title font-w700 mb-15">faturamento</div>
          <input
            class="calc-box h-72 mb-20 flex align-center justify-center font-w600 font-averta b-none"
            disabled
            v-bind:value="csalesAmount"
            v-money="csalesAmount !== null ? money : null"
            v-bind:class="{ active: csalesAmount != 0 }"
          />
        </div>
      </div>
    </div>

    <Accordion v-if="showResult" />
  </div>
</template>

<script>
import axios from 'axios';

import Accordion from './Accordion';

import assertInt from '../string';

export default {
  name: 'Result',
  component: { Accordion },
  props: {
    hasResult: {
      type: Boolean,
    },
    pSalesAmount: {
      type: String,
    },
    pLeads: {
      type: String,
    },
    pOpportunity: {
      type: String,
    },
    pProspect: {
      type: String,
    },
    pSales: {
      type: String,
    },
    pTeam: {
      type: String,
    },
    pTicketMedium: {
      type: String,
    },
  },
  data() {
    return {
      enableTerms: false,
      showResult: this.hasResult,
      submitDisable: true,
      salesAmount: '',
      leads: '',
      opportunity: '',
      prospect: '',
      sales: '',
      team: '',
      ticketMedium: '',
      shareFacebook: 'https://www.facebook.com/sharer/sharer.php?u=https://funil.com',
      shareLinkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=https://funil.com',
      shareFunilCopy: 'https://funil.com',
      form: {
        name: '',
        email: '',
        result: '',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
    };
  },
  computed: {
    actionUrl() {
      return '/api/v1/contacts';
    },
    csalesAmount() {
      return this.salesAmount;
    },
    cResultTeamCapacity() {
      return Math.round(this.ticketMedium * ((this.opportunity / this.leads) + 0.05)
      * ((this.salesAmount / this.ticketMedium) / ((this.sales / this.opportunity) + 0.01)
      / ((this.opportunity / this.leads) + 0.02) / ((this.leads / this.prospect) + 0.02) + 250 * this.team)
      * ((this.leads / this.prospect) + 0.05) * ((this.sales / this.opportunity) + 0.02));
    },
    cResultCurrentFunnel() {
      return this.sales * this.ticketMedium;
    },
  },
  mounted() {
    this.getUrlParams();
  },
  methods: {
    formSubmission() {
      this.goToResult();
    },
    goToResult() {
      // BAD workaround because they need to be numbers
      const resultData = {
        name: this.form.name,
        email: this.form.email,
        leads: this.pLeads.replace(/\./g, ''),
        opportunity: this.pOpportunity.replace(/\./g, ''),
        prospect: this.pProspect.replace(/\./g, ''),
        sales: this.pSales.replace(/\./g, ''),
        salesAmount: this.pSalesAmount.replace(/\./g, ''),
        team: this.pTeam.replace(/\./g, ''),
        ticketMedium: this.pTicketMedium.replace(/\./g, ''),
      };

      // call the API
      axios.post('/api/v1/contacts', resultData).then((res) => {
        window.location = `${window.location.protocol}//${window.location.host}/?r=${res.data.redirect}`;
      }).catch(() => {
        // TODO: handle error
      });
    },
    copy() {
      this.$refs.copy.select();
      document.execCommand('copy');
    },
    getUrlParams() {
      const params = new URLSearchParams(window.location.search);
      const resultData = params.get('r');
      try {
        const data = JSON.parse(atob(resultData));

        this.leads = assertInt(data.leads);
        this.opportunity = assertInt(data.opportunity);
        this.prospect = assertInt(data.prospect);
        this.sales = assertInt(data.sales);
        this.salesAmount = assertInt(data.salesAmount);
        this.team = assertInt(data.team);
        this.ticketMedium = assertInt(data.ticketMedium);
      } catch (e) {
        // ignore bad format URLs
      }

      this.form.result = window.location.href;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixin.scss";
.opacity {
  opacity: 0.1;
}
.clip-path {
  &::after {
    content: '';
    clip-path: ellipse(50% 37% at 50% 50%);
    background-color: $bg-blue-300;
    width: 130%;
    height: 100%;
    top: -50px;
    height: 600px;
    opacity: 0.3;
    left: 0;
    @media (min-width: 992px) {
      position: absolute;
      width: 170%;
      top: -85px;
      left: -35px;
    }
    @media (min-width: 1201px) {
      width: 100%;
      top: -50px;
      left: 0;
    }
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (min-width: 992px) {
      position: absolute;
      z-index: 5;
    }
    li {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 80px;
      align-items: center;
      margin-bottom: 25px;
      padding-right: 20px;
      color: $white;
      font-weight: bold;
      font-size: 18px;
      @media (min-width: $sm-breakpoint) {
        font-size: 24px;
      }
    }
  }
}

.quiz {
  padding-bottom: 80px;
  p {
    font-size: 1.25rem;
    line-height: 32px;
  }
  form {
    display: flex;
    flex-direction: column;
    input {
      border: 3px solid #2D3135;
      padding: 23px;
      padding-right: 34px;
      margin-bottom: 20px;
      font-size:  1rem;
    }
    .btns {
      display: flex;
      align-items: center;
      flex-direction: column;
      @media (min-width: $md-breakpoint) {
        flex-direction: initial;
      }
      &-submit, a {
        width: auto;
        margin-bottom: 20px;
        @media (min-width: $md-breakpoint) {
          margin-bottom: 0px;
        }
      }
      &-submit {
        position: relative;
        input {
          background-color: transparent;
          cursor: pointer;
          &:hover {
            @include shadow();
          }
        }
        i {
          position: absolute;
          right: 13px;
          top: 27px;
        }
      }
      a {
        color: $txt-link-grey;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      button {
        font-family: $font-inter;
        font-size:  1rem;
      }
    }
  }
  .copy {
    span, label {
      font-weight: 500;
    }
    label, input {
      font-size: 1rem;
    }
    .group-icon {
      position: relative;
      display: flex;
      align-items: center;
      i {
        position: absolute;
        right: 18px;
        font-size: 25px;
        cursor: pointer;
      }
      input {
        width: 100%;
        background-color: transparent;
        border: 3px solid #2D3135;
        padding: 24px;
        padding-right: 60px;
        color: $txt-input-grey;
      }
    }
    ul {
      display: inline-flex;
      li {
        margin-right: 23px;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $primary-color;
          text-decoration: none;
          i {
            font-size: 2.1875rem;
          }
        }
      }
    }
  }
  .funis {
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (min-width: $md-breakpoint) {
      flex-direction: initial;
    }
    .title {
      font-size: 30px;
      color: $primary-color;
    }
    .calc {
      &.prospect {
        .calc-title {
          color: $bg-dark-blue;
        }
        .calc-box {
          background-color: $bg-dark-blue;
          width: 100%;
        }
      }
      &.leads {
        .calc-title {
          color: $bg-blue;
        }
        .calc-box {
          background-color: $bg-blue;
          width: 100%;
          @media (min-width: $sm-breakpoint) {
            width: 80%;
          }
        }
      }
      &.opportunities {
        .calc-title {
          color: $bg-light-blue;
        }
        .calc-box {
          background-color: $bg-light-blue;
          width: 100%;
          @media (min-width: $sm-breakpoint) {
            width: 65%;
          }
        }
      }
      &.sales {
        .calc-title {
          color: $bg-light-green;
        }
        .calc-box {
          background-color: $bg-light-green;
          width: 100%;
          @media (min-width: $sm-breakpoint) {
            width: 40%;
          }
        }
      }
      &.total {
        .calc-box {
          background-color: $bg-yellow-total;
          color: $primary-color;
          font-size: 2.1875rem;
          width: 100%;
          @media (min-width: $sm-breakpoint) {
            width: 65%;
          }
        }
        .calc-title {
          color: $bg-yellow-total;
        }
      }
      &-title {
        font-size: 20px;
      }
      &-box {
        height: 85px;
        font-size: 55px;
        color: $white;
        margin: 0 auto;
        text-align: center;
      }
      &-percent {
        background-color: $txt-link-grey;
        height: 47px;
        width: 25%;
        font-size: 22px;
        color: $white;
        margin: 0 auto
      }
    }
    .h-72 {
      height: 72px;
    }
  }
}
</style>
